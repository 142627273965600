import React from "react";
import Chero from "../components/chero";
import Head from "../components/head";
import C1 from "../components/pages/candidates/c1/c1";
import C2 from "../components/pages/candidates/c2/c2";
const RegularPage = () => (
  <>
    <Head
      title="Candidates"
      dis="Premise Healthcare supports all candidates from start to finish of each placement and beyond. Our dedicated Consultants are available 24/7, 365 days and most importantly we do our very best to ensure you are always happy with your bookings, training requirements and much more"
    />
    <Chero herotitle="Candidates" />
    <C1 />
    <C2 />
  </>
);

export default RegularPage;
