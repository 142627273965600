import { Link } from "gatsby";
import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import "./c2.scss";
export default () => {
  return (
    <div className="c2 container">
      <div className="c-card">
        <h5>Doctors</h5>
        <div>
          <p>
            Premise Healthcare covers a multitude of healthcare speciality
            sectors, including: Doctors, Nurses and Surgeons. We also service
            clients in the private health.
            <br />
            <br />
            Premise Healthcare understands you’re a specialist in your field,
            therefore your career deserves our specialist attention. Our
            dedicated, speciality-based recruitment consultants will work
            closely with you in order to understand your requirements, assisting
            with the full registration process. As well as this we ensure to
            supply high calibre healthcare professionals into organisations
            across the UK. We build partnerships and believe in the longevity of
            your career development. <br />
            <br />
            <em>
              Committed to providing a first class recruitment solution with a
              personal touch to all our locum doctors: The benefits include:
            </em>
            <br />
          </p>
          <ul>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              Emergency Medicine / A&E Consultant and Non Consultants
            </li>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              Surgery Consultant and Non Consultants
            </li>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              Medicine Non Consultant
            </li>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              Medicine Consultants
            </li>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              Radiology Consultant and Non Consultants
            </li>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              Paediatrics and Obs and Gynae Consultant and Non Consultants
            </li>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              Anaesthetics Consultant and Non Consultants
            </li>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              Psychiatry Consultant and Non Consultants
            </li>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              General Practitioners GP
            </li>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              Dentistry
            </li>
          </ul>
        </div>
      </div>
      <div className="c-card">
        <h5>Nursing</h5>
        <div>
          <p>
            At Premise Healthcare you are a partner in our business, meaning you
            are guaranteed a personal service every time. <br />
            <br /> Premise Healthcare was started as a Nursing Specialised
            Agency and to this day we’ve had a very strong and fast growing
            presence within the industry and our clients. Some of whom include
            the NHS and Private sector businesses. We’ve managed to do this by
            only supplying the highest quality of professional nursing staff.{" "}
            <br />
            <br />{" "}
            <em>
              Premise Healthcare Nursing division specialise in the supply of :
            </em>
          </p>
          <ul>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              General Nurses (RGN)
            </li>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              Mental health Nurses (RMN)
            </li>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              Learning disability Nurses (RNLD)
            </li>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              Community Nurses (RGN, RNLD, RMN)
            </li>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              ALL Speciality Nurses such as Theatre staff, PICU, NICU, RM, A&E
              etc
            </li>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              ALL Senior Staff such as Hospital Directors, Hospital Managers,
              Matrons, Sisters etc
            </li>
          </ul>
          <br />
          <br />
          <p>
            Premise Healthcare’s clients prefer working with them because they
            know the level and standard of service being delivered by both
            healthcare professionals and the consultants who are dedicated to
            achieving the maximum results in everything that we do. By
            constantly pushing and developing ourselves, we have become a
            trusted partner that people can rely on for excellence nationwide.
            <br />
            <br />
            Premise Healthcare provides its services to a number of clients,
            this includes the NHS, Private Sectors and many of the local
            authorities across England and Wales. We supply to some of the
            largest care groups in the UK, and continuously remain at the
            forefront of contact with new care clients and hospitals to ensure
            we continue to organically fulfil our candidates and clients needs.{" "}
            <br />
            <br />
            We are searching for the best nurses for temporary and permanent
            assignments across the UK. <br />
            <br />
            Our consultants provide a tailored and personalised service to suit
            your individual needs. Always ensuring that we take the time to find
            the perfect match for each individual who applies to Premise
            Healthcare.
          </p>
        </div>
      </div>
      <div className="c-card">
        <h5>AHP/HSS</h5>
        <div>
          <p>
            Premise Healthcare’s AHP/HSS team aligned with our extensive
            experience in the sector, ensure that we are able to offer you
            fantastic positions across the UK. At rates that you will love.
            <br />
            <br />
            With numerous contracts and agreements, both NHS and Private, allow
            our recruitment consultants to tailor their service to you. Ensuring
            that you get the positions that you really want. Then we place our
            locum AHPs across a number of fields from Occupational Therapy and
            Speech Therapy to Physiotherapy and Psychology with opportunities
            ranging from short term and fixed term contracts to permanent
            placements. <br />
            <br />
            <em>Premise Healthcare recruits for:</em>
          </p>
          <ul>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              Speech and Language Therapy
            </li>

            <li>
              <span>
                <FaCheckCircle />
              </span>
              Occupational Therapy
            </li>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              Physiotherapy
            </li>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              Psychology
            </li>
          </ul>
          <br />
          <br />
          <p>
            <em>
              We also have dedicated consultants recruiting for the following
              sectors:
            </em>
          </p>
          <ul>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              Radiography
            </li>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              Pharmacy
            </li>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              Dietetics
            </li>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              Cardiology
            </li>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              Biomedical Science
            </li>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              Mortuary
            </li>
          </ul>
          <br />
          <br />
          <p>
            Our consultants provide a tailored and personalised service to suit
            your individual needs. Always ensuring that we take the time to find
            the perfect match for each individual who applies to Premise
            Healthcare.{" "}
          </p>
        </div>
      </div>
      <div className="c-card">
        <h5>Healthcare Assistants</h5>
        <div>
          <p>
            Premise Healthcare was started as a Nursing Specialised Agency and
            to this day we’ve had a very strong and fast growing presence within
            the industry and our clients. Some of whom include the NHS and
            Private sector businesses. We’ve managed to do this by only
            supplying the highest quality of professional nursing staff.
            <br />
            <br />
            <em>Premise Healthcare also specialise in the supply of :</em>{" "}
            <br />
            <br />
          </p>
          <ul>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              Healthcare Assistants
            </li>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              Social Workers
            </li>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              Community Healthcare Assistant
            </li>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              Mental Healthcare Assistants
            </li>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              And more…
            </li>
          </ul>
          <br />
          <br />
          <p>
            Our clients choose to work with us because they know the level of
            service being delivered by both healthcare professionals and
            consultants alike. By constantly pushing and developing ourselves,
            we have become a trusted partner that people can rely on for
            excellence nationwide.
            <br />
            <br />
            We provide our services to a number of clients, these include the
            NHS, Private Sectors and many of the local authorities across
            England and Wales. Supplying to some of the largest care groups in
            the UK, and continuously remain at the forefront of contact with new
            care clients and hospitals. Therefore ensuring that we continue to
            organically fulfil our candidates and clients needs.
            <br />
            <br />
            We’re searching for the best Healthcare Assistants for temporary and
            contract assignments across the UK.
            <br />
            <br />
            Our consultants provide a tailored and personalised service to suit
            your individual needs. Always ensuring that we take the time to find
            the perfect match for each individual who applies to Premise
            Healthcare.
          </p>
        </div>
      </div>
      {/* <div className="c-card">
        <h5>NMNC | Non-Medical Non-clinical</h5>
        <div>
          <p>
            Premise Healthcare has a team that provides Clerical (NMNC) recruitment
            solutions to the NHS nationwide.
            <br />
            <br />
            Look no further! Premise Healthcare already supply the NHS with clinical
            staff on a temporary basis and permanent so why not use Premise Healthcare for
            your Clerical needs too. We help the NHS have value for money.
            Premise Healthcareâ€™s philosophy of continuous improvement ensures we
            always try to exceed client and candidate expectations.
            <br />
            <br />
            Premise Healthcare’s reputation in the market place for providing a
            first class service has meant that more and more Trusts are asking
            us to provide admin and clerical staff.
            <br />
            <br />
            <em>
              Alongside Locums, Nurses and AHP/HSS, from a clerical perspective
              Premise Healthcare can supply:
              <br />
              <br />
            </em>
          </p>
          <ul>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              Administration Clerks
            </li>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              Ward Clerks
            </li>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              Receptionists
            </li>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              Secretaries
            </li>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              Medical Secretaries
            </li>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              Team Administrators
            </li>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              Project Officers
            </li>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              Medical PAs
            </li>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              Porters
            </li>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              Catering staff
            </li>
          </ul>
          <br />
          <br />
          <p>
            <em>NMNC | Benefits:</em>
          </p>
          <br />
          <br />
          <ul>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              All temporary admin and clerical staff are compliant to GPS and
              NHS Employers standards and interested in working in the NHS.
            </li>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              Dedicated consultant assigned to your trust
            </li>
          </ul>
        </div>
      </div> */}
      <div className="c-card">
        <h5>International Recruitment</h5>
        <div>
          <p>
            Premise Healthcare is delighted to announce our expansion into UAE,
            Australia and the British Isles. Due to this we’re able to offer
            exclusive opportunities for Health professionals in some of the most
            exciting parts of the world. Most importantly however, we have
            established key clients and are able to progress your medical career
            further.
            <br />
            <br />
            Premise Healthcare is also happy to support with your transition
            into the UK, for more information on this do not hesitate to{" "}
            <Link to="contact-us">get in touch</Link>.
            <br />
            <br />
            Premise Healthcare’s International Division knows the importance of
            making the right move for you and your loved ones. Our experienced
            team have worked Internationally and therefore understand the
            importance of perfecting the transition process. Nothing is more
            important to us that ensuring you and your family move with ease.
          </p>
        </div>
      </div>
      <div className="c-card">
        <h5>Revalidation Appraisals</h5>
        <div>
          <p>
            We are pleased to announce that we have been awarded designated body
            status. One of our statutory responsibilities as a designated body
            is to appoint a Responsible Officer. Our Responsible Officer is Dr
            Ian Grant, a Consultant Haematologist. Dr Grant is ultimately
            responsible for the revalidation of Doctors who have a prescribed
            connection to Premise Healthcare.
            <br />
            <br />
            We have also appointed Healthcare Licensing Support Ltd (HLS), an
            independent organisation who work with Dr Grant, to manage the
            appraisal and revalidation service for our locum Doctors. All
            Doctors with a UK licence to practice must participate in an annual
            appraisal and are subject to the revalidation process at year 5.
            <br />
            <br />
            HLS Ltd have a wealth of knowledge and expertise in this field and
            have processes in place to support you with your appraisals and
            revalidation. They can also appraise Doctors without a connection to
            a designated body.
          </p>
          <br />
          <br />
          <h5>Purpose of revalidation</h5>
          <p>
            The purpose of Revalidation is to ensure that medical professionals
            are keeping up to date with their chosen field or specialty to
            ensure safe and effective practice. This means that medical
            practitioners can be confident in providing the highest level of
            care to their patients, which in turn assists with their career
            development and delivery.
            <br />
            <br />
            Since December 2012, it has been a mandatory requirement for every
            doctor with a licence to practice in the UK to engage in the
            Revalidation process.
            <br />
            <br />
            Since April 2016, it will be the new process for Nurses and Midwives
            in the UK. Revalidation is straightforward and will help you as a
            nurse or midwife demonstrate that you practise safely and
            effectively. It will encourage you to reflect on the role of the
            Code in your practice and demonstrate that you are ‘living’ the
            standards set out within it.
            <br />
            <br />
            This new process replaces the Prep requirements, and you will have
            to revalidate every three years as a nurse or midwife to renew your
            registration.
            <br />
            <br />
            Premise Healthcare has dedicated Revalidation teams to guide locum
            doctors and nurses/ midwives through every step of Revalidation.
            <em>
              Every doctor with a GMC licence to practice in the UK is expected
              to revalidate once every 5 years. In order to prepare for
              revalidation, the GMC requires you to:
            </em>
          </p>
          <ul>
            <li>1. Participate in a yearly appraisal</li>
            <li>
              2. Collect feedback from colleagues and patients once in each
              revalidation cycle <br />
              <br />
              All nurses and midwives with a NMC licence in the UK is expected
              to revalidate once every 3 years. In order to prepare for
              revalidation, the NMC requires you to:
            </li>
          </ul>
          <br />
          <br />
          <ul>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              <a href="http://revalidation.nmc.org.uk/what-you-need-to-do/practice-hours">
                450 practice hours, or 900 if renewing as both a nurse and
                midwife
              </a>
            </li>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              <a href="http://revalidation.nmc.org.uk/what-you-need-to-do/continuing-professional-development">
                35 hours of CPD including 20 hours of participatory learning
              </a>
            </li>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              <a href="http://revalidation.nmc.org.uk/what-you-need-to-do/practice-related-feedback">
                Five pieces of practice-related feedback
              </a>
            </li>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              <a href="http://revalidation.nmc.org.uk/what-you-need-to-do/written-reflective-accounts">
                Five written reflective accounts
              </a>
            </li>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              <a href="http://revalidation.nmc.org.uk/what-you-need-to-do/reflective-discussion">
                Reflective discussion
              </a>
            </li>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              <a href="http://revalidation.nmc.org.uk/what-you-need-to-do/health-and-character">
                Health and character declaration
              </a>
            </li>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              <a href="http://revalidation.nmc.org.uk/what-you-need-to-do/professional-indemnity-arrangement">
                Professional indemnity arrangement
              </a>
            </li>
            <li>
              <span>
                <FaCheckCircle />
              </span>
              <a href="http://revalidation.nmc.org.uk/what-you-need-to-do/confirmation">
                Confirmation
              </a>
            </li>
          </ul>
          <br />
          <br />
          <p>
            Premise Healthcare is here to help with locum Revalidation,
            providing a first-class Revalidation support service to doctors and
            nurses/midwives.
            <br />
            <br />
            Revalidation will provide benefits for you as a doctor or nurse or
            midwife as well as the people you care for. It will help to
            encourage a culture of sharing, reflection and improvement and will
            be an ongoing process throughout your career.
            <br />
            <br />
            It is important to know that revalidation is not about making an
            assessment of your fitness to practise; it is about promoting good
            practice across the whole population of doctors and nurses and
            midwives, as well as strengthening public confidence in the medical
            or nursing and midwifery professions.
          </p>
        </div>
      </div>
    </div>
  );
};
